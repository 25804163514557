import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/Layout"
import Container from "../components/layout/Container"
import SEO from "../components/utility/SEO"
import LeadCapture from "../components/content/LeadCapture"

const RegisterInterestPage = ({ data: { prismicLeadCapturePage } }) => {
  const { data } = prismicLeadCapturePage
  return (
    <Layout selectCTAButton>
      <SEO title="Register interest" pathname="/register-interest" />
      <Container>
        <LeadCapture
          heading={data.heading.text}
          content={data.content.html}
          successMessage={data.success_message.html}
        />
      </Container>
    </Layout>
  )
}

export default RegisterInterestPage

export const pageQuery = graphql`
  query RegisterInterestPageQuery {
    prismicLeadCapturePage {
      data {
        heading {
          text
        }
        content {
          html
        }
        success_message {
          html
        }
      }
    }
  }
`
