import styled from 'styled-components';

const Textarea = styled.div`
  margin: ${({ theme }) => `${theme.space[1]} ${0} ${theme.space[3]}`};
  width: 100%;
`;

const TextareaInput = styled.textarea`
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  outline: none;

  padding: ${({ theme }) => theme.space[3]};
  font-size: ${({ theme }) => theme.fontSize[3]};
  border: 1px solid
    ${({ hasError, theme }) => (hasError ? theme.danger : theme.inputBorder)};
  border-radius: ${({ theme }) => theme.borderRadius[1]};
  color: ${({ theme }) => theme.inputColor};
  background: ${({ theme }) => theme.color.white};
  width: 100%;
  display: block;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily};

  &:focus {
    border-color: ${({ theme }) => theme.inputColor};
  }

  ::placeholder {
    color: ${({ theme }) => theme.inputPlaceholder};
  }

  :-webkit-autofill {
    box-shadow: 0 0 0 100px white inset;
    -webkit-box-shadow: 0 0 0 100px white inset;
    -webkit-text-fill-color: ${({ theme }) => theme.inputColor};
  }
`;

export default {
  Textarea,
  TextareaInput,
};
