import React from 'react';
import PropTypes from 'prop-types';
import Message from '../Message';

const InputHelpMessage = ({ children }) => {
  return (
    <Message icon="question-circle" size="s">
      {children}
    </Message>
  );
};

InputHelpMessage.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default InputHelpMessage;
