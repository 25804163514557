import styled from "styled-components"

const LeadCaptureView = styled.div`
  display: grid;
  margin-bottom: ${({ theme }) => theme.space[8]};

  @media ${({ theme }) => theme.device.tablet} {
    grid-gap: ${({ theme }) => theme.space[6]};
    grid-template-columns: 1fr ${({ theme }) => theme.space[12]};
  }

  @media ${({ theme }) => theme.device.laptop} {
    grid-gap: ${({ theme }) => theme.space[7]};
    grid-template-columns: 1fr ${({ theme }) => theme.space[13]};
  }

  @media ${({ theme }) => theme.device.laptopL} {
    grid-template-columns: 1fr ${({ theme }) => theme.space[14]};
  }
`

const LeadCapture = styled.div``

export default {
  LeadCaptureView,
  LeadCapture,
}
