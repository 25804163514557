import React from "react"
import PropTypes from "prop-types"
import PageContent from "../../common/PageContent"
import LeadCapture from "./Component"
import Styles from "./Styles"

const LeadCaptureView = ({ heading, content, successMessage }) => {
  return (
    <Styles.LeadCaptureView>
      <PageContent>
        <h2>{heading}</h2>
        <section dangerouslySetInnerHTML={{ __html: content }} />
      </PageContent>
      <LeadCapture successMessage={successMessage} />
    </Styles.LeadCaptureView>
  )
}

LeadCaptureView.propTypes = {
  heading: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  successMessage: PropTypes.string.isRequired,
}

export default LeadCaptureView
