import styled from 'styled-components';

const Badge = styled.span`
  font-size: ${({ size, theme }) => {
    if (size === 's') return theme.fontSize[0];
    if (size === 'l') return theme.fontSize[2];
    return theme.fontSize[1];
  }};
  padding: ${({ theme }) => `${theme.space[0]} ${theme.space[1]}`};
  min-width: ${({ theme }) => theme.space[7]};
  text-align: center;
  border-radius: ${({ theme }) => theme.borderRadius[3]};
  font-weight: 500;
  text-transform: uppercase;
  font-feature-settings: 'tnum';

  background-color: ${({ theme, mode }) => {
    if (mode === 'primary') return theme.color['primary-100'];
    if (mode === 'warning' || mode === 'waiting')
      return theme.color['warning-300'];
    if (mode === 'success' || mode === 'positive')
      return theme.color['success-200'];
    if (mode === 'danger' || mode === 'negative')
      return theme.color['danger-200'];
    return theme.color['gray-200'];
  }};

  color: ${({ theme, mode }) => {
    if (mode === 'primary') return theme.color['primary-500'];
    if (mode === 'warning' || mode === 'waiting')
      return theme.color['warning-700'];
    if (mode === 'success' || mode === 'positive')
      return theme.color['success-600'];
    if (mode === 'danger' || mode === 'negative')
      return theme.color['danger-500'];
    return theme.color['gray-500'];
  }};

  white-space: nowrap;

  svg {
    margin-right: ${({ theme }) => theme.space[1]};
  }
`;

const BadgeGroup = styled.div`
  display: flex;
  flex-direction: row;

  ${Badge} {
    margin: 0 ${({ theme }) => theme.space[0]};

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export default {
  Badge,
  BadgeGroup,
};
