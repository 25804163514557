import styled from 'styled-components';

const InputLabel = styled.label`
  margin-bottom: ${({ theme }) => theme.space[1]};
  font-size: ${({ theme }) => theme.fontSize[2]};
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  color: ${({ theme }) => theme.inputLabelColor};
  ${({ disabled }) => disabled && 'opacity: 0.8'};

  ${({ isHidden }) =>
    isHidden &&
    `position: absolute !important;
    height: 1px; 
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */`}
`;

export default {
  InputLabel,
};
