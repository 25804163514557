/* eslint-disable react/destructuring-assignment */
import React from "react"
import PropTypes from "prop-types"
import { Formik, Field } from "formik"
import * as Yup from "yup"

import Button from "../../common/Button"
import Form from "../../common/Form"
import ErrorMessage from "../../common/ErrorMessage"
import TextInput from "../../common/TextInput"
import Textarea from "../../common/Textarea"

const LeadCaptureForm = (props) => {
  const [displayError, setDisplayError] = React.useState()

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        company: "",
        email: "",
        // phone: "",
        // message: "",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        await fetch("/api/lead-capture", {
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
          body: JSON.stringify(values),
        })
          .then((res) => {
            if (res.ok) {
              return res.json()
            }
            if (res.status === "404") {
              setDisplayError({
                code: "Network",
                message: `${res.statusText}. There was an issue connecting to the server. Check your connection and retry.`,
              })
            }
            setDisplayError({
              code: res.status,
              message: res.statusText,
            })
            throw new Error(res.statusText)
          })
          .then(() => {
            // Do something with the response
            setSubmitting(false)
            props.successAction({ firstName: values.firstName })
          })
          .catch(() => {
            setSubmitting(false)
          })
      }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required("Enter your first name"),
        lastName: Yup.string().required("Enter your last name"),
        company: Yup.string().required("Enter your company or trading name?"),
        email: Yup.string()
          .email("That doesn't look like a valid email")
          .required("We need your email to contact you"),
        // phone: Yup.string().required("We need your phone number to contact you"),
        // message: Yup.string(),
      })}
    >
      {({ dirty, isSubmitting, errors, values, touched }) => (
        <Form name="lead-capture">
          <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
            <Field
              label="First name"
              id="firstName"
              name="firstName"
              placeholder="Enter your name"
              component={TextInput}
              isRequired
            />
            <Field
              label="Last name"
              id="lastName"
              name="lastName"
              placeholder="Enter your name"
              component={TextInput}
              isRequired
            />
            <Field
              label="Company"
              id="company"
              name="company"
              placeholder="Enter your company"
              component={TextInput}
            />
            {/* <Field
            label="Phone number"
            id="phone"
            name="phone"
            placeholder="Enter your phone number"
            component={TextInput}
            isRequired
          /> */}
            <Field
              label="Email"
              id="email"
              type="email"
              name="email"
              placeholder="Enter your email"
              component={TextInput}
              isRequired
            />
            {/* <Field
            label="Send us an optional message"
            id="message"
            name="message"
            placeholder="..."
            rows="6"
            component={Textarea}
          /> */}
            <ErrorMessage error={displayError} />
            <Button
              label="Submit"
              type="submit"
              mode="primary"
              size="l"
              block
              loadingLabel="Submitting"
              loading={isSubmitting}
              disabled={!dirty}
            />
          </fieldset>
        </Form>
      )}
    </Formik>
  )
}

LeadCaptureForm.propTypes = {
  successAction: PropTypes.func.isRequired,
}

export default LeadCaptureForm
