import styled from 'styled-components';

const Message = styled.div`
  display: flex;
  justify-content: ${({ center }) => (center ? 'space-around' : 'flex-start')};
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  text-align: ${({ center }) => (center ? 'center' : 'left')};

  padding: ${({ size, theme }) =>
    size === 'l'
      ? `${theme.space[4]} ${theme.space[3]}`
      : `${theme.space[1]} ${theme.space[2]}`};

  border-radius: ${({ theme }) => theme.borderRadius[1]};
  margin-bottom: ${({ theme }) => theme.space[3]};

  background-color: ${({ theme, mode }) => {
    if (mode === 'primary') return theme.color['primary-100'];
    if (mode === 'success') return theme.color['success-100'];
    if (mode === 'warning') return theme.color['warning-100'];
    if (mode === 'danger') return theme.color['danger-100'];
    return theme.color['gray-100'];
  }};

  color: ${({ theme, mode }) => {
    if (mode === 'primary') return theme.color['primary-900'];
    if (mode === 'success') return theme.color['success-800'];
    if (mode === 'warning') return theme.color['warning-800'];
    if (mode === 'danger') return theme.color['danger-500'];
    return theme.color['gray-600'];
  }};

  .inner {
    a {
      word-break: ${({ wrapLinks }) =>
        wrapLinks ? 'break-all' : 'break-word'};
    }

    width: 100%;
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    display: block;
  }

  .inner > :first-child {
    margin-top: 0;
  }

  .inner > :last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: ${({ theme }) => theme.space[2]};
    height: ${({ theme }) => theme.fontSize[3]};
    width: ${({ theme }) => theme.fontSize[3]};
    color: ${({ theme, mode }) => {
      if (mode === 'primary') return theme.color['primary-800'];
      if (mode === 'success') return theme.color['success-800'];
      if (mode === 'warning') return theme.color['warning-800'];
      if (mode === 'danger') return theme.color['danger-500'];
      return theme.color['gray-500'];
    }};
  }

  a {
    text-decoration: underline;
    color: ${({ theme, mode }) => {
      if (mode === 'primary') return theme.color['primary-900'];
      if (mode === 'success') return theme.color['success-800'];
      if (mode === 'warning') return theme.color['warning-800'];
      if (mode === 'danger') return theme.color['danger-500'];
      return theme.color['gray-700'];
    }};
  }

  a:hover {
    opacity: 0.8;
  }

  font-size: ${({ size, theme }) => {
    if (size === 's') return theme.fontSize[1];
    return theme.fontSize[2];
  }};

  @media ${({ theme }) => theme.device.mobileL} {
    font-size: ${({ size, theme }) => {
      if (size === 'l') return theme.fontSize[3];
      return theme.fontSize[2];
    }};
  }

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ size, theme }) => {
      if (size === 's') return theme.fontSize[1];
      if (size === 'l') return theme.fontSize[3];
      return theme.fontSize[2];
    }};
  }

  p {
    max-width: ${({ center, theme }) => (center ? theme.space[16] : 'none')};
    margin: ${({ center }) => (center ? '0 auto' : 'auto')};
    font-size: ${({ size, theme }) => {
      if (size === 's') return theme.fontSize[1];
      if (size === 'l') return theme.fontSize[3];
      return theme.fontSize[2];
    }};
  }
`;

export default {
  Message,
};
