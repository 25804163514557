import React from "react"
import PropTypes from "prop-types"
import Styles from "./Styles"
import Form from "./Form"
import Button from "../../common/Button"
import Panel from "../../common/Panel"

const LeadFormComponent = ({ successMessage }) => {
  const [submitted, setSubmitted] = React.useState(false)
  const [firstName, setFirstName] = React.useState(undefined)

  return (
    <Styles.LeadCapture>
      <Panel>
        {!submitted ? (
          <Form
            successAction={({ firstName }) => {
              setFirstName(firstName)
              setSubmitted(true)
              window.scrollTo(0, 0)
            }}
          />
        ) : (
          <div className="success-message">
            <h3>
              {firstName ? `Thanks ${firstName}` : `Thank you for your enquiry`}
            </h3>
            <div dangerouslySetInnerHTML={{ __html: successMessage }} />
            <Button
              label="Reset form"
              onClick={() => {
                setSubmitted(false)
              }}
              mode="link"
              block
            />
          </div>
        )}
      </Panel>
    </Styles.LeadCapture>
  )
}

LeadFormComponent.propTypes = {
  successMessage: PropTypes.string,
}

LeadFormComponent.defaultProps = {
  successMessage: undefined,
}

export default LeadFormComponent
